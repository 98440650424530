import { createMuiTheme, Theme, responsiveFontSizes, createStyles, makeStyles, darken } from "@material-ui/core/styles";
import {FontWeight, Colors, toRems, FontSize, ComponentColors} from './utils/style';

const AppTheme: Theme = createMuiTheme({
	palette: {
		primary: {
			// light: will be calculated from palette.primary.main,
			main: Colors.Primary
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		secondary: {
			//light: "#0066ff",
			main: Colors.Secondary,
			// dark: will be calculated from palette.secondary.main,
			//contrastText: "#ffcc00"
		},
		// third accent color, for action buttons
		info: {
			//light: "#0066ff",
			main: Colors.Accent,
			// dark: will be calculated from palette.secondary.main,
			//contrastText: "#ffcc00"
		},
		error: {
			//light: "#0066ff",
			main: Colors.RedDark, //red
			// dark: will be calculated from palette.secondary.main,
			//contrastText: "#ffcc00"
		},
		success: {
			//light: "#0066ff",
			main: Colors.Green, // green
			// dark: will be calculated from palette.secondary.main,
			//contrastText: "#ffcc00"
		},
		warning: {
			//light: "#0066ff",
			main: Colors.Gold,
			// dark: will be calculated from palette.secondary.main,
			//contrastText: "#ffcc00"
		},
		// Used by `getContrastText()` to maximize the contrast between
		// the background and the text.
		//contrastThreshold: 3,
		// Used by the functions below to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		//tonalOffset: 0.2
	},
	typography: {
		fontFamily: [
			'Open Sans',
			'sans-serif'
		].join(','),
		fontWeightMedium: FontWeight.Medium,
		h1: {
			fontSize: toRems(20),
			color: Colors.BlackLight3,
			fontWeight: FontWeight.Bold
		},
		h2: {
			fontSize: toRems(18),
			color: Colors.BlackLight2,
			fontWeight: FontWeight.Bold
		},
		h3: {
			fontSize: toRems(16),
			fontWeight: FontWeight.Bold
		},
		h4: {
			fontSize: toRems(15),
			fontWeight: FontWeight.Bold
		},
		h5: {
			fontSize: toRems(14.5),
			fontWeight: FontWeight.Bold
		},
		h6: {
			fontSize: toRems(14),
			fontWeight: FontWeight.Bold
		},
		htmlFontSize: FontSize.Desktop
	},
	// global component overrides
	overrides: {
		// Style sheet name ⚛️
		MuiTableCell: {
			paddingCheckbox: {
				padding: 0
			}
		},
		MuiButton: {
			label: {
				textTransform: 'none',
				fontWeight: FontWeight.Regular
			},
			root: {
				padding: '5px 16px',
			},
		},
		MuiAvatar: {
			root: {
				width: toRems(60),
				height: toRems(60),
			}
		},
		MuiListItemAvatar: {
			root: {
				minWidth: toRems(60),
			}
		}
	}
});
responsiveFontSizes(AppTheme);

export default AppTheme;


export const globalStyles = (theme: Theme) => ({
	"@global": {
		// MUI typography elements use REMs, so you can scale the global
		// font size by setting the font-size on the <html> element.
		html: {
			fontSize: FontSize.Desktop,
			overflowX: 'hidden',
			height: '100%',

			[theme.breakpoints.down("sm")]: {
				fontSize: FontSize.Mobile
			},
		},
		body: {
			backgroundColor: `${ComponentColors.BackgroundGray}`,
			overflowX: 'hidden',
			position: 'relative',
			height: '100%',
		},
		figcaption: {
			fontSize: theme.typography.pxToRem(13),
			padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(14)}`,
			color: Colors.GrayDarker,
		},
		'.MuiButton-containedPrimary.actionBtn': {
			backgroundColor: AppTheme.palette.info.main,
			color: AppTheme.palette.info.contrastText,

			'&:hover': {
				backgroundColor: darken(AppTheme.palette.info.main, .15),
				color: AppTheme.palette.info.contrastText,
			},

			'& .MuiButton-label': {
				fontWeight: FontWeight.Bold
			},
		}
	}
});


export const useResponsiveStyles = makeStyles((theme: Theme) =>
	createStyles({
		responsiveContainer: {
			position: 'relative',
			overflow: 'hidden',
			paddingTop: '56.25%', // defaults to 16:9
			height: 0,
			width: '100%',

			'& > .responsive': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			},

			'& > .iframe': {
				border: 0,
			}
		},
	})
);