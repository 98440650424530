import {
    IAssessmentState,
    TypeAssessmentAction,
	SET_ASSESSMENT_LOADING,
    SET_ASSESSMENT,
    SET_CACHED_ASSESSMENT,
    SET_TEAM_MEMBERS,
    SET_CURRENT_TEAM_MEMBER,
    SHOW_ASSESSMENT_ERROR,
    RESTORE_CACHED_ASSESSMENT,
    GET_CACHED_ASSESSMENT
} from './assessment.types';

export const INITIAL_STATE:IAssessmentState = {
	isLoading: true,
    assessments: undefined,
    cachedChecked: false,
	error: null
};

const assessmentReducer = (state = INITIAL_STATE, action:TypeAssessmentAction):IAssessmentState => {
    switch( action.type ){
        
        case SET_ASSESSMENT_LOADING :
            return {
                ...state,
                isLoading: action.payload
            };

        case SET_ASSESSMENT :
            return {
                ...state,
                assessments: action.payload
            };
        
        case SET_CACHED_ASSESSMENT :
            if( !action.payload ){
                // reset
                return {
                    ...INITIAL_STATE
                };
            } else {
                return state;
            }
            
        case SET_TEAM_MEMBERS :
            return {
                ...state,
                teamMembers: action.payload,
                currentTeamMember: action.payload.length ? action.payload[0] : undefined
            };

        case SET_CURRENT_TEAM_MEMBER :
            return {
                ...state,
                currentTeamMember: action.payload
            };
		
		case SHOW_ASSESSMENT_ERROR :
			return {
                ...state,
                error: action.payload
            };
            
        case RESTORE_CACHED_ASSESSMENT :
            return {
                ...state,
                assessments: action.payload.assessments,
                currentTeamMember: action.payload.currentTeamMember,
                teamMembers: action.payload.teamMembers,
                isLoading: false
            }

        case GET_CACHED_ASSESSMENT :
            return {
                ...state,
                cachedChecked: true
            }

        default :
            return state;
    }
};

export default assessmentReducer;