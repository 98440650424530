import {takeLatest, put, all, call} from 'redux-saga/effects';
import axios from 'axios';
import {
	GET_PLAYER, 
	IPlayer, 
	Player,
	IGetPlayer
} from './player.types';
import {showError, setLoading, setPlayer} from './player.actions';
import {ITeam, Team} from '../team/team.types';
import {setTeam} from '../team/team.actions';
import {LoadableCorrectiveType} from '../correctives/correctives.types';
import {setCorrectives} from '../correctives/correctives.actions';
import {convertAllCorrectivesRemoteData, LoadableCorrectiveRemoteType} from '../correctives/correctives.sagas';

export interface IPlayerRemote extends IPlayer {
	team?:ITeam;
	correctives?:LoadableCorrectiveRemoteType[]
}

export function* getPlayer({payload}:IGetPlayer) {
	yield put(
		setLoading(true)
	);
	console.log('GET PLAYER', payload)
	
	// get info
	let definedPlayer = false;
	try {
		const api = (payload.correctives && payload.team) ? `${process.env.PUBLIC_URL}/data/playerAndTeamAndCorrectives.json` : 
			payload.team ? `${process.env.PUBLIC_URL}/data/playerAndTeam.json` : `${process.env.PUBLIC_URL}/data/player.json`;
		const playerInfoData = yield axios(api);
		const playerInfo:IPlayerRemote = playerInfoData?.data as IPlayerRemote;
		if( playerInfo ){
			
			const {team:teamInfo, correctives:correctivesInfo, ...otherPlayerInfo} = playerInfo;
			
			const player:IPlayer = convertPlayerRemoteData({
				...otherPlayerInfo,
				id: payload.id /* Test for now, set ID for testing */
			}); 

			const team:Team | undefined = teamInfo ? Team.create(teamInfo) : undefined;

			const correctives:LoadableCorrectiveType[] | undefined = correctivesInfo ? convertAllCorrectivesRemoteData(correctivesInfo) : undefined;

			if( team ){
				yield put(
					setTeam(team)
				);
			}
			
			yield put(
				setPlayer(player)
			);

			if( correctives ){
				
				yield put(
					setCorrectives({
						correctives,
						playerId: player.id
					})
				);
			}
			definedPlayer = true;
		} else {
			throw new Error('Player API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showError('Get player data failed.')
		);
	}

	if( !definedPlayer ){
		yield put(
			setLoading(false)
		);
	}
    
}

export const convertPlayerRemoteData = ({correctives, team, ...memberdata}:IPlayerRemote):Player => {
	const player = Player.create({
		...memberdata
	});
	return player;
}

export function* onGetPlayer() {
    yield takeLatest(GET_PLAYER, getPlayer);
}

export function* playerSagas() {
    yield all([
		call( onGetPlayer )
    ]);
}