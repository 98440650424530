import {takeLatest, put, all, call} from 'redux-saga/effects';
import axios from 'axios';
import {
	GET_EXERCISE, 
	IGetExercise, 
	IExerciseComplete,
} from './exercise.types';
import {showError, setExercise} from './exercise.actions';



export function* getExercise({payload: {id}}:IGetExercise) {
	
	// get info
	try {
		const api = `${process.env.PUBLIC_URL}/data/exercise_${id}.json`;
		const exerciseInfoData = yield axios(api);
		const exercise:IExerciseComplete = exerciseInfoData?.data as IExerciseComplete;
		if( exercise ){
			
			yield put(
				setExercise(exercise)
			);
		} else {
			throw new Error('Exercise API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showError('Get exercise data failed.')
		);
	}
    
}

export function* onGetExercise() {
    yield takeLatest(GET_EXERCISE, getExercise);
}

export function* exerciseSagas() {
    yield all([
		call( onGetExercise )
    ]);
}