import React from "react";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { Sizes, } from '../../utils/style';
import Copyright from "../copyright/copyright.component";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: 20
	},
	toolbar: {
		width: "90%",
		maxWidth: Sizes.MaxWidth,
		marginLeft: "auto",
		marginRight: "auto",
		justifyContent: 'center',
	}
}));

type FooterProps = {};

export const Footer: React.FC<FooterProps> = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root} >
			<Toolbar className={clsx(classes.toolbar)}>
				<Copyright />
			</Toolbar>
		</Box>
	);
};

export default Footer;
