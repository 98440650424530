import {IPlayer} from '../player/player.types';

export const SET_ASSESSMENT_LOADING = 'SET_ASSESSMENT_LOADING';
export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const SET_ASSESSMENT = 'SET_ASSESSMENT';
export const GET_CACHED_ASSESSMENT = 'GET_CACHED_ASSESSMENT';
export const SET_CACHED_ASSESSMENT = 'SET_CACHED_ASSESSMENT';
export const RESTORE_CACHED_ASSESSMENT = 'RESTORE_CACHED_ASSESSMENT';
export const SHOW_ASSESSMENT_ERROR = 'SHOW_ASSESSMENT_ERROR';
export const SET_TEAM_MEMBERS = 'SET_TEAM_MEMBERS';
export const SET_CURRENT_TEAM_MEMBER = 'SET_CURRENT_TEAM_MEMBER';

// Data types
export interface IData {
	id:string;
}
export interface ITest extends IData {
	
	name:string;
	left?:number;
	right?:number;
	goal?:number; // perfect score, defaults to 50
}

export interface IAssessment extends IData {
	name:string;
	tests:ITest[];
	memberId:string;
}

//export type AssessmentMapType = Map<string, IAssessment>;

export interface AssessmentMapInterface {
	[key:string]: IAssessment;
}

/* Track a team member's results and progress */
/*export interface IAssessmentProgress extends IAssessment {
	memberId:string;
}*/


// State type
export interface IAssessmentState {
	isLoading:boolean;
	assessments?:AssessmentMapInterface;
	teamMembers?:IPlayer[];
	currentTeamMember?:IPlayer;
	error?:(string | null);
	cachedChecked:boolean;
}


// Action types
export interface IGetAssessment {
	type: typeof GET_ASSESSMENT;
	payload: IPlayer[];
};

export interface ISetAssessmentLoading {
    type: typeof SET_ASSESSMENT_LOADING;
    payload: boolean;
};

export interface ISetAssessment {
    type: typeof SET_ASSESSMENT;
    payload:AssessmentMapInterface;
}

export interface IGetCachedAssessment {
	type: typeof GET_CACHED_ASSESSMENT;
};

export interface ICachedAssessmentPayload {
	assessments:AssessmentMapInterface;
	teamMembers:IPlayer[];
	currentTeamMember:IPlayer;
}
export interface ISetCachedAssessment {
	type: typeof SET_CACHED_ASSESSMENT;
	payload:ICachedAssessmentPayload | null;
};

export interface IRestoreCachedAssessment {
	type: typeof RESTORE_CACHED_ASSESSMENT;
	payload:ICachedAssessmentPayload;
};

export interface IShowAssessmentError {
    type: typeof SHOW_ASSESSMENT_ERROR;
    payload:string;
}

export interface ISetTeamMembers {
    type: typeof SET_TEAM_MEMBERS;
    payload:IPlayer[];
}

export interface ISetCurrentTeamMember {
    type: typeof SET_CURRENT_TEAM_MEMBER;
    payload:IPlayer;
}


// Classes
export class Test implements ITest {
	constructor(
		public readonly id:string,
		public readonly name: string,
		public left?:number,
		public right?:number
	){}

	static create({id, name, left, right}:ITest):Test {
		return new Test(id, name, left, right);
	}
}

export class Assessment implements IAssessment {
	constructor(
		public readonly id:string,
		public readonly name:string,
		public readonly memberId:string,
		public readonly tests:ITest[]
	){}

	static create({id, name, memberId, tests}:IAssessment):Assessment {
		return new Assessment(id, name, memberId, tests);
	}
}

export type TypeAssessmentAction = IGetAssessment  | ISetAssessmentLoading | ISetAssessment | IShowAssessmentError | ISetTeamMembers | ISetCurrentTeamMember | IGetCachedAssessment | ISetCachedAssessment | IRestoreCachedAssessment;