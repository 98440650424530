import {
    SHOW_ASSESSMENT_ERROR,
    SET_ASSESSMENT_LOADING, 
    RESTORE_CACHED_ASSESSMENT,
    GET_CACHED_ASSESSMENT,
    SET_CACHED_ASSESSMENT,
    IShowAssessmentError, 
    ISetAssessmentLoading, 
    ISetAssessment, 
    SET_ASSESSMENT, 
    ISetCurrentTeamMember, 
    SET_CURRENT_TEAM_MEMBER,
    GET_ASSESSMENT, 
    IGetAssessment,
    IGetCachedAssessment,
    ISetCachedAssessment,
    ICachedAssessmentPayload,
    IRestoreCachedAssessment,
    SET_TEAM_MEMBERS,
    ISetTeamMembers,
    AssessmentMapInterface
} from './assessment.types';
import {IPlayer} from '../player/player.types';

export const showError = (error:string):IShowAssessmentError => ({
    type: SHOW_ASSESSMENT_ERROR,
    payload: error
});

export const setLoading = (val:boolean):ISetAssessmentLoading => ({
    type: SET_ASSESSMENT_LOADING,
    payload: val
});

export const setAssessment = (assessmentMap:AssessmentMapInterface):ISetAssessment => ({
    type: SET_ASSESSMENT,
    payload: assessmentMap
});

export const getAssessment = (members:IPlayer[]):IGetAssessment => ({
    type: GET_ASSESSMENT,
    payload: members
});

export const setAssessmentTeamMembers = (members:IPlayer[]):ISetTeamMembers => ({
    type: SET_TEAM_MEMBERS,
    payload: members
});

export const setCurrentTeamMember = (member:IPlayer):ISetCurrentTeamMember => ({
    type: SET_CURRENT_TEAM_MEMBER,
    payload: member
});

export const cacheAssessment = (assessmentInfo:ICachedAssessmentPayload | null):ISetCachedAssessment => ({
    type: SET_CACHED_ASSESSMENT,
    payload: assessmentInfo
});

export const getCachedAssessment = ():IGetCachedAssessment => ({
    type: GET_CACHED_ASSESSMENT
});

export const restoreAssessment = (assessmentInfo:ICachedAssessmentPayload):IRestoreCachedAssessment => ({
    type: RESTORE_CACHED_ASSESSMENT,
    payload: assessmentInfo
});