import React, {ComponentPropsWithRef, memo} from 'react';
import clsx from "clsx";
import Avatar, {AvatarProps as OriginalAvatarProps} from '@material-ui/core/Avatar';

interface IAvatarProps<T> extends ComponentPropsWithRef<'div'> {
	fallback?:string;
	src:string | null | undefined;
}
export type AvatarProps = IAvatarProps<HTMLDivElement> & Omit<OriginalAvatarProps<'div'>, 'src'>;

const CustomAvatar = React.forwardRef<HTMLDivElement, AvatarProps>(({src, alt, fallback, className, children, ...props}, ref) => {
	const realAlt = src ? alt : undefined;
	const usingFallback = !src;
	const realSrc:string = src ? src : fallback ? fallback : '/broken-image.jpg';
	
	return (
        <Avatar ref={ref} src={realSrc} alt={realAlt} className={clsx(className, usingFallback && 'fallback')} {...props}>{children}</Avatar>
    );
});

export default memo(CustomAvatar);