import {ISetPlayer} from '../player/player.types';
import {IData, IExercise} from '../exercise/exercise.types';

export const SET_CORRECTIVES_LOADING = 'SET_CORRECTIVES_LOADING';
export const GET_ALL_CORRECTIVES = 'GET_ALL_CORRECTIVES';
export const SHOW_ALL_CORRECTIVES_ERROR = 'SHOW_ALL_CORRECTIVES_ERROR';
export const GET_CORRECTIVE = 'GET_CORRECTIVE';
export const SHOW_CORRECTIVE_ERROR = 'SHOW_CORRECTIVE_ERROR';
export const UPDATE_CORRECTIVE = 'UPDATE_CORRECTIVE';
export const SET_ALL_CORRECTIVES = 'SET_ALL_CORRECTIVES';

// Data types
export enum CorrectiveStatus {
	ToDo = 0, // not started
	Skipped = 1,
	InProgress = 3,
	Completed = 4
}
export interface ICorrectiveExercise extends IExercise {
	status: CorrectiveStatus;
}
export interface ICorrectiveDay extends IData {
	exercises:ICorrectiveExercise[];
	duration:string;
	assignmentDate:string;
	status: CorrectiveStatus;
}
export enum CorrectiveType {
	Corrective = 'corrective',
	Training = 'training'
}
export interface ICorrectiveLimited extends IData {
	name:string;
	error?:string;
	type:CorrectiveType;
}
export interface ICorrective extends ICorrectiveLimited {
	days:ICorrectiveDay[];
}
export type LoadableCorrectiveType = ICorrectiveLimited | ICorrective;

export interface ICorrectiveErrorsMap {
	[correctiveId:string]:string;
}

export interface ICorrectivesMap {
	[correctiveId:string]:LoadableCorrectiveType;
}


// State type
export interface ICorrectivesState {
	isLoading:boolean;
	playerId?:string;
	correctivesOrder?:string[],
	correctivesMap:ICorrectivesMap;
	error?:(string | null);
	correctiveErrorsMap:ICorrectiveErrorsMap;
}


// Action types
export interface IGetAllCorrectives {
	type: typeof GET_ALL_CORRECTIVES;
	payload: string; // player ID
};
export interface ISetCorrectivesLoading {
	type: typeof SET_CORRECTIVES_LOADING;
	payload: boolean;
};
export interface IShowAllCorrectivesError {
    type: typeof SHOW_ALL_CORRECTIVES_ERROR;
    payload:string;
}


export interface IGetCorrectivePayload {
	id:string;
	playerId:string;
}
export interface IGetCorrective {
	type: typeof GET_CORRECTIVE;
	payload: IGetCorrectivePayload;
};

export interface IShowGetCorrectiveErrorPayload {
	correctiveId:string;
	error:string;
}
export interface IShowGetCorrectiveError {
    type: typeof SHOW_CORRECTIVE_ERROR;
    payload:IShowGetCorrectiveErrorPayload;
}

export interface ISetAllCorrectivesPayload {
	correctives:LoadableCorrectiveType[];
	playerId:string;
}
export interface ISetAllCorrectives {
	type: typeof SET_ALL_CORRECTIVES;
	payload:ISetAllCorrectivesPayload;
};

export interface IUpdateCorrectivePayload {
	corrective:ICorrective;
	playerId:string;
}
export interface IUpdateCorrective {
	type: typeof UPDATE_CORRECTIVE;
	payload: IUpdateCorrectivePayload;
};

export type TypeCorrectivesAction = ISetCorrectivesLoading | IGetAllCorrectives | IShowAllCorrectivesError | IGetCorrective | IShowGetCorrectiveError | ISetAllCorrectives | IUpdateCorrective | ISetPlayer;