import {
    SET_EXERCISE_LOADING,
	GET_EXERCISE,
	SET_EXERCISE,
	SHOW_EXERCISE_ERROR,
	IGetExercise,
	ISetExercise,
	IExerciseComplete,
	ISetExerciseLoading,
	IShowExerciseError,
	IData
} from './exercise.types';

export const showError = (error?:string):IShowExerciseError => ({
    type: SHOW_EXERCISE_ERROR,
    payload: error
});

export const setLoading = (val:boolean):ISetExerciseLoading => ({
    type: SET_EXERCISE_LOADING,
    payload: val
});

export const setExercise = (exercise?:IExerciseComplete):ISetExercise => ({
    type: SET_EXERCISE,
    payload: exercise
});

export const getExercise = (config:IData):IGetExercise => ({
    type: GET_EXERCISE,
    payload: config
});