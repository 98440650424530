import {all, call} from 'redux-saga/effects';

import {teamSagas} from './team/team.sagas';
import {playerSagas} from './player/player.sagas';
import {userSagas} from './user/user.sagas';
import {assessmentSagas} from './assessment/assessment.sagas';
import {correctivesSagas} from './correctives/correctives.sagas';
import {assessmentsSagas} from './assessments/assessments.sagas';
import {exerciseSagas} from './exercise/exercise.sagas';

export default function* rootSaga() {
    yield all([
        call(userSagas),
        call(teamSagas),
        call(playerSagas),
        call(assessmentSagas),
        call(correctivesSagas),
        call(assessmentsSagas),
        call(exerciseSagas)
    ])
};