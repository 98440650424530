import {ISetPlayer} from '../player/player.types';
import {IAssessment, IData} from '../assessment/assessment.types';

export const SET_PLAYER_ASSESSMENTS_LOADING = 'SET_PLAYER_ASSESSMENTS_LOADING';
export const GET_ALL_PLAYER_ASSESSMENTS = 'GET_ALL_PLAYER_ASSESSMENTS';
export const SHOW_ALL_PLAYER_ASSESSMENTS_ERROR = 'SHOW_ALL_PLAYER_ASSESSMENTS_ERROR';
export const GET_PLAYER_ASSESSMENT = 'GET_PLAYER_ASSESSMENT';
export const SHOW_PLAYER_ASSESSMENT_ERROR = 'SHOW_PLAYER_ASSESSMENT_ERROR';
export const UPDATE_PLAYER_ASSESSMENT = 'UPDATE_PLAYER_ASSESSMENT';
export const SET_ALL_PLAYER_ASSESSMENTS = 'SET_ALL_PLAYER_ASSESSMENTS';

// Data types
export interface IAssessmentCompleted extends IAssessment {
	date:string;
}

export interface IAssessmentsProgram extends IData {
	name:string;
	days:IAssessmentCompleted[];
	error?:string;
}

export interface IAssessmentsProgramLimited extends IData {
	name:string;
	error?:string;
}
export type LoadableProgramType = IAssessmentsProgramLimited | IAssessmentsProgram;

export interface IAssessmentsMap {
	[programId:string]:LoadableProgramType;
}
export interface IAssessmentsErrorsMap {
	[programId:string]:string;
}



// State type
export interface IAssessmentsState {
	isLoading:boolean;
	playerId?:string;
	programOrder?:string[],
	programMap:IAssessmentsMap;
	error?:(string | null);
	assessmentsErrorsMap:IAssessmentsErrorsMap;
}


// Action types
export interface IGetAllPlayerAssessments {
	type: typeof GET_ALL_PLAYER_ASSESSMENTS;
	payload: string; // player ID
};
export interface ISetPlayerAssessmentsLoading {
	type: typeof SET_PLAYER_ASSESSMENTS_LOADING;
	payload: boolean;
};
export interface IShowAllPlayerAssessmentsError {
    type: typeof SHOW_ALL_PLAYER_ASSESSMENTS_ERROR;
    payload:string;
}

export interface IGetPlayerAssessmentPayload {
	id:string;
	playerId:string;
}
export interface IGetPlayerAssessment {
	type: typeof GET_PLAYER_ASSESSMENT;
	payload: IGetPlayerAssessmentPayload;
};

export interface IShowGetPlayerAssessmentErrorPayload {
	programId:string;
	error:string;
}
export interface IShowGetPlayerAssessmentError {
    type: typeof SHOW_PLAYER_ASSESSMENT_ERROR;
    payload:IShowGetPlayerAssessmentErrorPayload;
}

export interface ISetAllPlayerAssessmentsPayload {
	programs:LoadableProgramType[];
	playerId:string;
}
export interface ISetAllPlayerAssessments {
	type: typeof SET_ALL_PLAYER_ASSESSMENTS;
	payload:ISetAllPlayerAssessmentsPayload;
};

export interface IUpdatePlayerAssessmentPayload {
	program:IAssessmentsProgram;
	playerId:string;
}
export interface IUpdatePlayerAssessment {
	type: typeof UPDATE_PLAYER_ASSESSMENT;
	payload: IUpdatePlayerAssessmentPayload;
};

export type TypeAssessmentsAction = IGetAllPlayerAssessments | ISetPlayerAssessmentsLoading | IShowAllPlayerAssessmentsError | IGetPlayerAssessment |
IShowGetPlayerAssessmentError | ISetAllPlayerAssessments | IUpdatePlayerAssessment | ISetPlayer;