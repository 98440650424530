import React, { ErrorInfo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    imageOverlay: {
        height: '60vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },

    imageContainer: {
        display: 'inline-block',
        backgroundImage: 'url(https://i.imgur.com/yW2W9SC.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '40vh',
        height: '40vh'
    },

    headline: {
        fontSsize: '28px',
        color: '#2f8e89'
    }
    
}));

const ErrorContent:React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.imageOverlay}>
            <div className={classes.imageContainer} />
            <h2 className={classes.headline}>Sorry this page is broken.</h2>
        </div>
    )
}

type Props = {};
type State = {
    hasErrored:boolean;
}
class ErrorBoundary extends React.Component<Props, State> {
   
    state:State = {
        hasErrored: false
    }
    
    static getDerivedStateFromError(error:Error){
        // process the error
        return { hasErrored: true };
    }
    
    componentDidCatch(error:Error, info:ErrorInfo) {
        console.log(error);
    }

    render() {
        if( this.state.hasErrored ){
            return (
                <ErrorContent />
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;