import {
    IPlayerState,
    TypePlayerAction,
    GET_PLAYER,
	SET_PLAYER_LOADING,
    SET_PLAYER,
    SHOW_PLAYER_ERROR
} from './player.types';

export const INITIAL_STATE:IPlayerState = {
	isLoading: true,
    player: undefined,
    error: null
};

const playerReducer = (state = INITIAL_STATE, action:TypePlayerAction):IPlayerState => {
    switch( action.type ){
        
        case GET_PLAYER :
            /* Reset */    
            return {
                ...INITIAL_STATE
            };
        
        case SET_PLAYER_LOADING :
            return {
                ...state,
                isLoading: action.payload
            };
            
        case SET_PLAYER :
            return {
                ...state,
                player: action.payload,
                isLoading: !action.payload
			};
		
		case SHOW_PLAYER_ERROR :
			return {
                ...state,
                error: action.payload
            };
        
        default :
            return state;
    }
};

export default playerReducer;