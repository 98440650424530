import {
    ISetPlayerAssessmentsLoading,
    IGetAllPlayerAssessments,
    IShowAllPlayerAssessmentsError,
    IGetPlayerAssessment,
    IGetPlayerAssessmentPayload,
    IShowGetPlayerAssessmentError,
    IShowGetPlayerAssessmentErrorPayload,
    IUpdatePlayerAssessmentPayload,
    IUpdatePlayerAssessment,
    ISetAllPlayerAssessments,
    ISetAllPlayerAssessmentsPayload,
    SET_PLAYER_ASSESSMENTS_LOADING,
    SHOW_ALL_PLAYER_ASSESSMENTS_ERROR,
    GET_ALL_PLAYER_ASSESSMENTS,
    GET_PLAYER_ASSESSMENT,
    SHOW_PLAYER_ASSESSMENT_ERROR,
    UPDATE_PLAYER_ASSESSMENT,
    SET_ALL_PLAYER_ASSESSMENTS
} from './assessments.types';

export const setLoading = (val:boolean):ISetPlayerAssessmentsLoading => ({
    type: SET_PLAYER_ASSESSMENTS_LOADING,
    payload: val
});

export const getAllAssessmentPrograms = (playerId:string):IGetAllPlayerAssessments => ({
    type: GET_ALL_PLAYER_ASSESSMENTS,
    payload: playerId
});

export const showAllAssessmentsError = (error:string):IShowAllPlayerAssessmentsError => ({
    type: SHOW_ALL_PLAYER_ASSESSMENTS_ERROR,
    payload: error
});

export const getAssessmentProgram = (config:IGetPlayerAssessmentPayload):IGetPlayerAssessment => ({
    type: GET_PLAYER_ASSESSMENT,
    payload: config
});

export const showGetAssessmentProgramError = (config:IShowGetPlayerAssessmentErrorPayload):IShowGetPlayerAssessmentError => ({
    type: SHOW_PLAYER_ASSESSMENT_ERROR,
    payload: config
});

export const updateAssessmentProgram = (config:IUpdatePlayerAssessmentPayload):IUpdatePlayerAssessment => ({
    type: UPDATE_PLAYER_ASSESSMENT,
    payload: config
});

export const setAllAssessmentPrograms = (config:ISetAllPlayerAssessmentsPayload):ISetAllPlayerAssessments => ({
    type: SET_ALL_PLAYER_ASSESSMENTS,
    payload: config
});