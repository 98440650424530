import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import userReducer from './user/user.reducer';
import playerReducer from './player/player.reducer';
import teamReducer from './team/team.reducer';
import assessmentReducer from './assessment/assessment.reducer';
import correctivesReducer from './correctives/correctives.reducer';
import assessmentsReducer from './assessments/assessments.reducer';
import exerciseReducer from './exercise/exercise.reducer';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['user', 'player', 'team', 'correctives', 'assessments', 'exercise'],
    blacklist: ['assessment'],
    stateReconciler: autoMergeLevel2
};

const rootReducer = combineReducers({
    user: userReducer,
    player: playerReducer,
    team: teamReducer,
    assessment: assessmentReducer,
    correctives: correctivesReducer,
    assessments: assessmentsReducer,
    exercise: exerciseReducer
});

// @ts-ignore
export default persistReducer(persistConfig, rootReducer);