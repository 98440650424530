export enum StorageKey {
	User = 'user',
	Assessment = 'assessment'
}

type StorageReturnType = any | null;

export const getSession = (key:string):StorageReturnType => {
	const stored = sessionStorage.getItem(key);
	return stored ? JSON.parse(stored) : null;
}

export const setSession = (key:string, obj:any) => {
	sessionStorage.setItem(key, JSON.stringify(obj));
}

export const deleteSession = (key:string) => {
	sessionStorage.removeItem(key);
}

export const getLocal = (key:string):StorageReturnType => {
	const stored = localStorage.getItem(key);
	return stored ? JSON.parse(stored) : null;
}

export const setLocal = (key:string, obj:any) => {
	localStorage.setItem(key, JSON.stringify(obj));
}

export const deleteLocal = (key:string) => {
	localStorage.removeItem(key);
}