import {
	SET_EXERCISE_LOADING,
	GET_EXERCISE,
	SET_EXERCISE,
	SHOW_EXERCISE_ERROR,
	TypeExerciseAction,
	IExerciseState
} from './exercise.types';

export const INITIAL_STATE:IExerciseState = {
	isLoading: true,
    exercise: undefined,
	error: undefined
};

const exerciseReducer = (state = INITIAL_STATE, action:TypeExerciseAction):IExerciseState => {
    switch( action.type ){
        
        case GET_EXERCISE :
            /* Reset */    
            return {
                ...INITIAL_STATE
            };
        
        case SET_EXERCISE_LOADING :
            return {
                ...state,
                isLoading: action.payload
            };
            
        case SET_EXERCISE :
            return {
                ...state,
                exercise: action.payload,
                isLoading: !action.payload
			};
		
		case SHOW_EXERCISE_ERROR :
			return {
                ...state,
				error: action.payload,
				isLoading: false
            };
        
        default :
            return state;
    }
};

export default exerciseReducer;