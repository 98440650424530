import {
    SET_PLAYER_LOADING,
    GET_PLAYER,
    SET_PLAYER,
    SHOW_PLAYER_ERROR,
    IGetPlayer,
    ISetPlayerLoading,
    ISetPlayer,
    IShowPlayerError,
    IPlayer,
    IGetPlayerPayload,
} from './player.types';

export const showError = (error:string):IShowPlayerError => ({
    type: SHOW_PLAYER_ERROR,
    payload: error
});

export const setLoading = (val:boolean):ISetPlayerLoading => ({
    type: SET_PLAYER_LOADING,
    payload: val
});

export const setPlayer = (member?:IPlayer):ISetPlayer => ({
    type: SET_PLAYER,
    payload: member
});

export const getPlayer = (config:IGetPlayerPayload):IGetPlayer => ({
    type: GET_PLAYER,
    payload: config
});