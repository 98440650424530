import {
    ICorrectivesState,
    TypeCorrectivesAction,
    SET_CORRECTIVES_LOADING,
    SHOW_ALL_CORRECTIVES_ERROR,
    SHOW_CORRECTIVE_ERROR,
    UPDATE_CORRECTIVE,
    SET_ALL_CORRECTIVES,
    LoadableCorrectiveType,
    ICorrectivesMap
} from './correctives.types';

import {SET_PLAYER} from '../player/player.types';

export const INITIAL_STATE:ICorrectivesState = {
	isLoading: true,
	playerId: undefined,
	correctivesOrder: undefined,
	correctivesMap: {},
	error: null,
	correctiveErrorsMap: {}
};

const correctivesReducer = (state = INITIAL_STATE, action:TypeCorrectivesAction):ICorrectivesState => {
    switch( action.type ){
        
        case SET_PLAYER :
            const newPId = action.payload ? action.payload.id : undefined;
            if( state.playerId!==newPId ){
                /* Reset for new player */    
                return {
                    ...INITIAL_STATE,
                    playerId: newPId
                };
            } else {
                return state;
            }
        
        case SET_ALL_CORRECTIVES :
            if( action.payload.playerId===state.playerId){
                return {
                    ...state,
                    correctivesOrder: action.payload.correctives.map((corrective:LoadableCorrectiveType) => corrective.id),
                    correctivesMap: action.payload.correctives.reduce((acc:ICorrectivesMap, corrective:LoadableCorrectiveType) => {
                        acc[corrective.id] = corrective;
                        return acc;
                    }, {})
                };
            } else {
                return state;
            }
            
        case SET_CORRECTIVES_LOADING :
            return {
                ...state,
                isLoading: action.payload
			};
		
		case SHOW_ALL_CORRECTIVES_ERROR :
			return {
                ...state,
                error: action.payload
            };

        case SHOW_CORRECTIVE_ERROR :
            return {
                ...state,
                correctiveErrorsMap: {
                    ...state.correctiveErrorsMap,
                    [action.payload.correctiveId]: action.payload.error
                }
            };

        case UPDATE_CORRECTIVE :
            if( action.payload.playerId===state.playerId){
                const {correctivesMap} = state;
                return {
                    ...state,
                    correctivesMap: {
                        ...correctivesMap,
                        [action.payload.corrective.id]: action.payload.corrective
                    }
                };
            } else {
                return state;
            }

        default :
            return state;
    }
};

export default correctivesReducer;