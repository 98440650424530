import {
    TypeAssessmentsAction,
    IAssessmentsState,
    LoadableProgramType,
    IAssessmentsMap,
    SET_PLAYER_ASSESSMENTS_LOADING,
    SHOW_ALL_PLAYER_ASSESSMENTS_ERROR,
    SHOW_PLAYER_ASSESSMENT_ERROR,
    UPDATE_PLAYER_ASSESSMENT,
    SET_ALL_PLAYER_ASSESSMENTS
} from './assessments.types';

import {SET_PLAYER} from '../player/player.types';

export const INITIAL_STATE:IAssessmentsState = {
	isLoading: true,
	playerId: undefined,
	programOrder: undefined,
	programMap: {},
	error: null,
	assessmentsErrorsMap: {}
};

const assessmentsReducer = (state = INITIAL_STATE, action:TypeAssessmentsAction):IAssessmentsState => {
    switch( action.type ){
        
        case SET_PLAYER :
            const newPId = action.payload ? action.payload.id : undefined;
            if( state.playerId!==newPId ){
                /* Reset for new player */    
                return {
                    ...INITIAL_STATE,
                    playerId: newPId
                };
            } else {
                return state;
            }
        
        case SET_ALL_PLAYER_ASSESSMENTS :
            if( action.payload.playerId===state.playerId){
                return {
                    ...state,
                    programOrder: action.payload.programs.map((program:LoadableProgramType) => program.id),
                    programMap: action.payload.programs.reduce((acc:IAssessmentsMap, program:LoadableProgramType) => {
                        acc[program.id] = program;
                        return acc;
                    }, {})
                };
            } else {
                return state;
            }
            
        case SET_PLAYER_ASSESSMENTS_LOADING :
            return {
                ...state,
                isLoading: action.payload
			};
		
		case SHOW_ALL_PLAYER_ASSESSMENTS_ERROR :
			return {
                ...state,
                error: action.payload
            };

        case SHOW_PLAYER_ASSESSMENT_ERROR :
            return {
                ...state,
                assessmentsErrorsMap: {
                    ...state.assessmentsErrorsMap,
                    [action.payload.programId]: action.payload.error
                }
            };

        case UPDATE_PLAYER_ASSESSMENT :
            if( action.payload.playerId===state.playerId){
                const {programMap} = state;
                return {
                    ...state,
                    programMap: {
                        ...programMap,
                        [action.payload.program.id]: action.payload.program
                    }
                };
            } else {
                return state;
            }

        default :
            return state;
    }
};

export default assessmentsReducer;