import {
    ITeamState,
    TypeTeamAction,
	SET_TEAM_LOADING,
	SET_TEAM,
	SHOW_ERROR
} from './team.types';

export const INITIAL_STATE:ITeamState = {
	isLoading: true,
	team: undefined,
	error: null
};

const teamReducer = (state = INITIAL_STATE, action:TypeTeamAction):ITeamState => {
    switch( action.type ){
        
        case SET_TEAM_LOADING :
            return {
                ...state,
                isLoading: action.payload
            };

        case SET_TEAM :
            return {
                ...state,
                team: action.payload
			};
		
		case SHOW_ERROR :
			return {
                ...state,
                error: action.payload
			};

        default :
            return state;
    }
};

export default teamReducer;