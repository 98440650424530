import React, { Fragment } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import sizer16x9 from '../../assets/sizer16x9.png';
import ResponsiveImage from '../responsive-image/responsonsive-image.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
	  color: '#fff',
	  position: 'absolute'
    },
  }),
);

enum Sizer {
	s16x9 = '16x9'
}

export interface SpinnerBackdropProps {
	isLoading?:boolean;
	sizer?:Sizer | string;
}
const SpinnerBackdrop:React.FC<SpinnerBackdropProps> = ({isLoading, sizer}) => {
	const classes = useStyles();
	return (
		<Fragment>
			<Backdrop
				className={classes.backdrop}
				open={!!isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			{isLoading && sizer && sizer===Sizer.s16x9 && (
				<ResponsiveImage src={sizer16x9} alt="Loading..." />
			)}
		</Fragment>
	)
};

export default SpinnerBackdrop;