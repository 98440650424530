import React, {useState, useEffect, useCallback, useRef} from 'react';
import 'intersection-observer';
import placeholder from '../assets/placeholder-image.png';
import SrcMap, {SrcMapType} from '../utils/src-map';
import {debounce} from 'lodash';

const lazyLoadedRefs = new Map<string, boolean>();

const ioObserver = new window.IntersectionObserver((entries, itemObserver) => {
    entries.forEach(entry => {
        if( entry.isIntersecting ){
            const observable = observables.find( entry.target );
            if( observable ){
                observable.callback();
            }
        }
    })
}, {
    rootMargin: '33.33%'
});
//ioObserver.POLL_INTERVAL = 100; // Time in milliseconds.


// Use this wrapper class so we can add a callback.
class ObservableElement {
    private _observing:boolean = true;
    
    constructor(
        public readonly element:Element,
        public readonly callback:()=>void
    ){
        ioObserver.observe( element );
    }

    unobserve = () => {
        if( this._observing ){
            this._observing = false;
            ioObserver.unobserve( this.element );
        }
    }

    get observing(){
        return this._observing;
    }
}

// Master list class of observables
class ObservableList {
    private list:ObservableElement[] = [];

    add = (element:Element, callback:()=>void) => {
        const obj = new ObservableElement(element, callback);
        this.list.push( obj );
        return obj;
    };

    find = (element:Element) => {
        return this.list.find(observable => observable.element===element);
    }

    remove = (observable:ObservableElement) => {
        const idx = this.list.indexOf(observable);
        if( idx !== -1 ){
            observable.unobserve();
            this.list.splice(idx, 1);
        }
    }
}
// Master list of Observables
const observables = new ObservableList();

const hasAlreadyLazyLoaded = (src:string) => lazyLoadedRefs.has(src);

type NullableFunction = null | Function;
//type RefType = React.Ref<HTMLImageElement | null> | React.MutableRefObject<HTMLImageElement | undefined | null> React.RefObject;
type RefType = React.MutableRefObject<HTMLImageElement | undefined> | React.RefObject<HTMLImageElement | undefined | null>;

const useImageLoader = (reference:RefType, srcMapping:SrcMapType, skipLazyLoad:boolean, placeholderSrc:string=placeholder) => {

    const srcMap = new SrcMap(srcMapping);

    const hasLoaded = skipLazyLoad || hasAlreadyLazyLoaded(srcMap.src);

    interface ILoaderState {
        src: string;
        srcset?: string;
        sizes?: string;
        loaded: boolean;
    }
    const initialState:ILoaderState = {
        src: hasLoaded ? srcMap.src : placeholderSrc,
        srcset: hasLoaded ? srcMap.srcSet : undefined,
        sizes: '1px',
        loaded: hasLoaded
    }

    const needsObserving = useRef<boolean>(!hasLoaded);
    const [srcPath, setSrcPath] = useState<string | undefined>(initialState.src);
    const [srcset, setSrcset] = useState<string | undefined>(initialState.srcset);

    const [loaded, setLoaded] = useState<boolean>(initialState.loaded);
    const [sizes, setSizes] = useState<string | undefined>(initialState.sizes);

    const checkSize = useCallback((force?:boolean) => {
        if( loaded || force ){
            if(reference && reference.current && reference.current instanceof HTMLImageElement){
                const newSize = Math.max(reference.current.clientWidth, 1);
                const prevSize = sizes ? parseFloat(sizes) : 0;
                const diff = Math.abs(newSize - prevSize);
                if(diff > 1){
                    setSizes( newSize + 'px' );
                }
            }
        }
    }, [reference, loaded, sizes]);

    const startObserving = useCallback(() => {
        let isObserving = false;
        let stopObserving:NullableFunction = null;

        if( needsObserving.current && reference.current ){
            needsObserving.current = false;

            const observable = observables.add( reference.current, () => {
                if( stopObserving ){
                    stopObserving();
                }

                // lazy-load
                lazyLoadedRefs.set(srcMap.src, true); // unique to this project, only care if this particular image has already loaded. Other project you may just stick to reference object.
                setLoaded(true);
                // maintain this order of setting sizes, srcset, then src
                checkSize(true);
                setSrcset( srcMap.srcSet );
                setSrcPath( srcMap.src );
            });
            isObserving = true;

            stopObserving = () => {
                if(isObserving){
                    isObserving = false;
                    observables.remove( observable );
                }
            };
        }

        return stopObserving ? stopObserving : null;
    }, [reference, srcMap, checkSize, needsObserving]);

    useEffect(() => {
        // mounting
        let stopObserving:NullableFunction = startObserving();
        let loadListening:HTMLImageElement | undefined;

        const handleResize = debounce(() => {
            checkSize();
        }, 100);
        window.addEventListener('resize', handleResize);

        if(reference && reference.current && reference.current instanceof HTMLImageElement){
            loadListening = reference.current;
            loadListening.addEventListener('load', handleResize);
        }
        checkSize();

        return () => {
            // unmounting
            window.removeEventListener('resize', handleResize);
            if( loadListening ){
                loadListening.removeEventListener('load', handleResize);
                loadListening = undefined;
            }

            if( stopObserving ){
                stopObserving();
                stopObserving = null;
            }
        }
    }, [startObserving, checkSize, reference]);

    return {
        src: srcPath,
        srcset,
        sizes,
        loaded
    };
}

export default useImageLoader;