import {takeLatest, put, all, call} from 'redux-saga/effects';
import axios from 'axios';
import {
	GET_ASSESSMENT,
	GET_CACHED_ASSESSMENT,
	SET_CACHED_ASSESSMENT,
	Assessment, 
	Test, 
	ITest, 
	IAssessment, 
	IGetAssessment,
	IGetCachedAssessment,
	ISetCachedAssessment,
	ICachedAssessmentPayload,
	AssessmentMapInterface
} from './assessment.types';
import {IPlayer} from '../player/player.types';
import {showError, setLoading, setAssessment, restoreAssessment} from './assessment.actions';
import {StorageKey, getSession, setSession, deleteSession} from '../../utils/storage';

interface ISavedAssessment {
	assessments:AssessmentMapInterface;
	teamMembers:IPlayer[];
	currentTeamMemberID:string;
}

export function* cacheAssessment({payload}:ISetCachedAssessment) {
	if( payload ){
		// store data
		const {assessments, teamMembers, currentTeamMember} = payload;
		const storedObj:ISavedAssessment = {
			assessments,
			teamMembers,
			currentTeamMemberID: currentTeamMember.id
		};
		yield setSession(StorageKey.Assessment, storedObj);
	} else {
		// delete data
		yield deleteSession(StorageKey.Assessment);
	}
	
}

export function* getCachedAssessment(action:IGetCachedAssessment) {
	const storedAssessments:ISavedAssessment | null = yield getSession(StorageKey.Assessment) as ISavedAssessment | null;
	if( storedAssessments && storedAssessments.teamMembers && storedAssessments.teamMembers.length ){
		const {assessments, teamMembers, currentTeamMemberID} = storedAssessments;
		const realAssessment:ICachedAssessmentPayload = {
			assessments,
			teamMembers,
			currentTeamMember: teamMembers.find(member => member.id===currentTeamMemberID)!
		}
		if(realAssessment && realAssessment.currentTeamMember && realAssessment.assessments){
			yield put(
				restoreAssessment(realAssessment)
			);
		}
	}
}

export function* getAssessment({payload:members}:IGetAssessment) {
	yield put(
		setLoading(true)
	);
	
	// get info
	try {
		const assessmentInfoData = yield axios('data/assessment.json');
		const assessmentInfo:IAssessment = assessmentInfoData?.data as IAssessment;
		if( assessmentInfo ){

			const assessmentMemberMap:AssessmentMapInterface = {};

			// get stored assesssments
			const cachedAssessmentMemberMap:AssessmentMapInterface | null | undefined = getSession(StorageKey.Assessment) as AssessmentMapInterface | null;

			members.forEach(({id:memberId}) => {
				const storedAssessment = cachedAssessmentMemberMap ? cachedAssessmentMemberMap[memberId] : null;
				if( storedAssessment ){
					// used stored
					assessmentMemberMap[memberId] = storedAssessment;
				} else {
					// create new instance
					const tests:Test[] = assessmentInfo?.tests?.map((test:ITest) => Test.create(test));

					const assessment:Assessment = Assessment.create({
						...assessmentInfo,
						memberId,
						tests
					});
					assessmentMemberMap[memberId] = assessment;
				}
			});
			
			yield put(
				setAssessment(assessmentMemberMap)
			);
		} else {
			throw new Error('Assessment API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showError('Get Assessment data failed.')
		);
	}

	yield put(
		setLoading(false)
	);
    
}

export function* onGetAssessment() {
    yield takeLatest(GET_ASSESSMENT, getAssessment);
}

export function* onCacheAssessment() {
    yield takeLatest(SET_CACHED_ASSESSMENT, cacheAssessment);
}

export function* onGetCacheAssessment() {
    yield takeLatest(GET_CACHED_ASSESSMENT, getCachedAssessment);
}

export function* assessmentSagas() {
    yield all([
		call( onGetAssessment ),
		call( onCacheAssessment ),
		call( onGetCacheAssessment )
    ]);
}