import MediaType from '../../utils/media-type';

export const SET_EXERCISE_LOADING = 'SET_EXERCISE_LOADING';
export const GET_EXERCISE = 'GET_EXERCISE';
export const SET_EXERCISE = 'SET_EXERCISE';
export const SHOW_EXERCISE_ERROR = 'SHOW_EXERCISE_ERROR';

// Data types
export interface IData {
	id:string;
}
export interface IExercise extends IData {
	name:string;
	description:string;
	sets:number;
	reps:number;
	rest:string;
	tempo:string;
	duration:string;
	weight?:string | null;
}

enum MediaRenderType {
	Carousel = 'carousel',
	List = 'list'
}
export interface IExerciseComplete extends IExercise {
	media:MediaType[];
	mediaRenderType?:MediaRenderType; // defaults to carousel
}


// State type
export interface IExerciseState {
	isLoading:boolean;
	exercise?:IExerciseComplete;
	error?:string;
}


// Action types
export interface IGetExercise {
	type: typeof GET_EXERCISE;
	payload: IData;
};
export interface ISetExercise {
	type: typeof SET_EXERCISE;
	payload: IExerciseComplete | undefined;
};
export interface ISetExerciseLoading {
	type: typeof SET_EXERCISE_LOADING;
	payload: boolean;
};
export interface IShowExerciseError {
    type: typeof SHOW_EXERCISE_ERROR;
    payload?:string;
}

export type TypeExerciseAction = IGetExercise | ISetExercise | ISetExerciseLoading | IShowExerciseError;