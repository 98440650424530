export type SrcMapType = Map<string | number, string>;
export type SrcMapEntryType = [string | number, string];
class SrcMap extends Map implements SrcMapType {
    constructor(map:SrcMapType){
        super();

        for(let [key, value] of Array.from(map)){
            this.set(key, value);
        }
    }

    get srcSet(){
        const srcset = [];
        for(let [key, value] of Array.from(this as SrcMapType)){
            if(key!=='src'){
                srcset.push(value + ' ' + key + 'w');
            }
        }
        return srcset.join(', ');
    }

    get src(){
        return this.get('src');
    }
    
    static parseAttribute(srcsetAttribute:string):SrcMapType {
        const map = new Map<string | number, string>();
        srcsetAttribute.split(',').forEach(source => {
            const image = source.split(' ');
            if( image.length ){
                let size = (image.length > 1) ? parseFloat(image[1]) : 'src';
                if(size==='src' || !isNaN(size as number)){
                    map.set(size, image[0].trim())
                }
            }
        });
        return map;
    }

    static createSrcMap(entries:SrcMapEntryType[]):SrcMapType {
        const srcMap = new Map<string | number, string>();
        entries.forEach(([size, source]) => {
            if(size && source!==undefined && source.trim()!==''){
                srcMap.set(size, source);
            }
        });
        return srcMap;
    }
}

export default SrcMap;