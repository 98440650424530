import {takeLatest, put, all, call} from 'redux-saga/effects';
import axios from 'axios';
import {GET_TEAM, Team, ITeam} from './team.types';
import {Player} from '../player/player.types';
import {showError, setLoading, setTeam} from './team.actions';
import {convertPlayerRemoteData, IPlayerRemote} from '../player/player.sagas';

export interface ITeamRemote extends Omit<ITeam, 'members'> {
    members:IPlayerRemote[];
}

export function* getTeam() {
	yield put(
		setLoading(true)
	);
	
	// get info
	try {
		const teamInfoData = yield axios('data/team.json');
		const teamInfo:ITeamRemote = teamInfoData?.data as ITeamRemote;
		if( teamInfo ){

			let members:Player[] | undefined;
			if( teamInfo.members && Array.isArray(teamInfo.members) ){
				members = teamInfo.members.map(convertPlayerRemoteData);
			}

			const team:Team = Team.create({
				...teamInfo,
				members
			});
			
			yield put(
				setTeam(team)
			);
		} else {
			throw new Error('Team API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showError('Get team data failed.')
		);
	}

	yield put(
		setLoading(false)
	);
    
}

export function* onGetTeam() {
    yield takeLatest(GET_TEAM, getTeam);
}

export function* teamSagas() {
    yield all([
        call( onGetTeam )
    ]);
}