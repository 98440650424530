import React, {Fragment} from "react";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from '@material-ui/icons/Home';
import CloseIcon from "@material-ui/icons/Close";
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import EmailIcon from '@material-ui/icons/Email';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import InfoIcon from '@material-ui/icons/Info';
import Routes from '../../utils/routes';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { LogoSrcMap, SiteName, ComponentColors, Colors } from '../../utils/style';
import ResponsiveImage from '../responsive-image/responsonsive-image.component';

import {
	makeStyles,
	useTheme,
	Theme,
	createStyles
} from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			[theme.breakpoints.up("sm")]: {
				width: drawerWidth,
				flexShrink: 0
			}
		},
		toolbar: {
			...theme.mixins.toolbar,
			display: "flex",
			backgroundColor: `${ComponentColors.NavBar}`,
			alignItems: 'center'
		},
		drawerPaper: {
			width: drawerWidth
		},
		grow: {
			flexGrow: 1
		},
		flex: {
			display: "flex"
		},
		logoContainer: {
			textAlign: 'center',
			
			'& img': {
				height: theme.typography.pxToRem(28)
			}
		},
		logo: {

		},
		closeBtn: {
			color: Colors.White
		}
	})
);

export interface NavDrawerProps {
	isOpen: boolean;
	onDrawerClose?: Function;
}

type MixedType = React.ElementType<React.SVGProps<SVGSVGElement>>;
interface NavOption {
	Icon:MixedType;
	link?:string;
}

const NavDrawer: React.FC<NavDrawerProps> = ({ isOpen, onDrawerClose }) => {
	const classes = useStyles();
	const theme = useTheme();

	const handleDrawerToggle = (bool:boolean) => {
		if (!bool && onDrawerClose) {
			onDrawerClose();
		}
	};

	const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		handleDrawerToggle(false);
	}

	
	type mainItemsType = [string, NavOption][];
	/*const mainItemsMap:mainItemsType = [
		['Home', {
			Icon: HomeIcon,
			link: Routes.HOME
		}],
		['Training', {
			Icon: FitnessCenterIcon
		}],
		['Messages', {
			Icon: EmailIcon
		}]
	];
	const mainItems = Array.from(new Map<string, NavOption>([
		['Home', {
			Icon: HomeIcon,
			link: Routes.HOME
		}],
		['Training', {
			Icon: FitnessCenterIcon
		}],
		['Messages', {
			Icon: EmailIcon
		}]
	]));*/
	const mainItems:mainItemsType = [
		['Home', {
			Icon: HomeIcon,
			link: Routes.HOME
		}],
		['Training', {
			Icon: FitnessCenterIcon
		}],
		['Messages', {
			Icon: EmailIcon
		}]
	];

	/*const subItems = Array.from(new Map<string, JSX.Element>([
		['Help', InfoIcon],
		['Support', LiveHelpIcon]
	]));*/
	type subItemsType = [string, MixedType][];
	const subItems:subItemsType = [
		['Help', InfoIcon],
		['Support', LiveHelpIcon]
	];

	const DrawerContent = (
		<div>
			<div className={classes.toolbar}>
				<div className={clsx(classes.grow, classes.logoContainer)}>
					<ResponsiveImage srcMapping={LogoSrcMap} alt={SiteName} sizeToHeight className={classes.logo} />
				</div>
				<div className={classes.flex}>
					<IconButton
						onClick={() => handleDrawerToggle(false)}
						aria-label="Close Nav"
						color="inherit" 
						className={clsx('close-btn', classes.closeBtn)}
					>
						<CloseIcon />
					</IconButton>
				</div>
			</div>
			<Divider />
			<List>
				{mainItems.map(
					([label, {Icon, link}], index) => (
						<Fragment key={label}>
							{link ? (
								<Link component={RouterLink} to={link} onClick={onLinkClick}>
									<ListItem button>
										<ListItemIcon>
											<Icon />
										</ListItemIcon>
										<ListItemText primary={label} />
									</ListItem>
								</Link>
							) : (
								<ListItem button>
									<ListItemIcon>
										<Icon />
									</ListItemIcon>
									<ListItemText primary={label} />
								</ListItem>
							)}
						</Fragment>
					)
				)}
			</List>
			<Divider />
			<List>
				{subItems.map(([label, Icon], index) => (
					<ListItem button key={label}>
						<ListItemIcon>
							<Icon />
						</ListItemIcon>
						<ListItemText primary={label} />
					</ListItem>
				))}
			</List>
		</div>
	);

	return (
		<nav className={classes.drawer} aria-label="nav links">
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Hidden smUp implementation="css">
				<SwipeableDrawer
					variant="temporary"
					anchor={theme.direction === "rtl" ? "right" : "left"}
					open={isOpen}
					onOpen={() => handleDrawerToggle(true)}
					onClose={() => handleDrawerToggle(false)}
					classes={{
						paper: classes.drawerPaper
					}}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
				>
					{DrawerContent}
				</SwipeableDrawer>
			</Hidden>
		</nav>
	);
};

export default NavDrawer;
