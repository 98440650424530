import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

const URL_BASE = `${process.env.PUBLIC_URL}`;

type IRoutes = {
	HOME:string;
	LOGIN:string;
	ASSESSMENT:string;
	PLAYER:string;
	EXERCISE:string;
}
const Routes:IRoutes = {
	HOME : `${URL_BASE}/`,
	LOGIN : `${URL_BASE}/login`,
	ASSESSMENT : `${URL_BASE}/assessment`,
	PLAYER : `${URL_BASE}/player/:playerId/:tab?`,
	EXERCISE : `${URL_BASE}/exercise/:exerciseId`
}

export type PlayerRouteParams = { 
	playerId: string;
	tab?: string;
};

export const getPlayerPath = (playerId:string):string => {
	return `${URL_BASE}/player/${playerId}`;
}

export type ExerciseRouteParams = { 
	exerciseId: string;
};

export const getExercisePath = (exerciseId:string):string => {
	return `${URL_BASE}/exercise/${exerciseId}`;
}

export interface IFromState {
	label:string;
	url:string;
}


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
type ChildrenType = Pick<React.HTMLProps<HTMLElement>, "children">;
type PrivateRouteProps = RouteProps & ChildrenType & {
	isAuthenticated: boolean;
	redirectPath?:string;
};
export const PrivateRoute: React.FC<PrivateRouteProps> = ({
	children,
	isAuthenticated,
	render,
	component,
	redirectPath=Routes.LOGIN,
	...rest
}) => {
	return isAuthenticated ? (
		<Route {...rest} render={render} component={component}>{children}</Route>
	) : (
		<Route {...rest}
			render={props =>
				<Redirect to={{ pathname: redirectPath, state: { from: props.location } }} />
			}
		/>
	)
};

export default Routes;
