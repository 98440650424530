import SrcMap from './src-map';
import loginImage1 from '../assets/man-about-to-lift-barbell-2261477.jpg';
import loginImage2 from '../assets/man-about-to-lift-barbell-2261477@2x.jpg';
import loginImage3 from '../assets/man-about-to-lift-barbell-2261477@3x.jpg';

// font weights based on what's provided by Google font
export enum FontWeight {
	Light = 300,
	Regular = 400,
	Medium = 600,
	Bold = 700
}

export enum Colors {
	Primary = '#2c2b29',
	Secondary = '#e7b900',
	Accent = '#2c2b29',
	White = '#fff',
	OffWhite = '#f6f6f6',
	Black = '#000',
	BlackLight1 = '#2c2b29',
	BlackLight2 = '#292929',
	BlackLight3 = '#1a1a1a',
	Gray = '#ededed',
	Gray2 = '#d0d0d0',
	GrayDark = '#878787',
	GrayDarker = '#3c3c3c',
	GrayLight = '#f1f1f1',
	GrayLighter = '#f5f5f5',
	RedDark = '#b80000',
	Green = '#2c9700',
	Gold = '#e7b900'
}

export enum ComponentColors {
	NavBar = Colors.Primary,
	Header = Colors.GrayDarker,
	BackgroundGray = Colors.Gray,
	TableHeaderGray = Colors.GrayLight,
	TableHeaderGrayContrastText = Colors.GrayDark,
	BorderGray = Colors.Gray2
}

// 16px is the default font-size used by browsers.
export enum FontSize {
	Desktop = 16,
	Mobile = 13
}

export enum Sizes {
	MaxWidth = 1280 // max layout width
}

export const toRems = (pixels:number):string => (pixels/FontSize.Desktop)+'rem';

export const toRemsMobile = (pixels:number):string => (pixels/FontSize.Mobile)+'rem';

export const LogoSrcMap = SrcMap.createSrcMap([
	[parseFloat(`${process.env.REACT_APP_LOGO_URL3_SIZE}`), `${process.env.REACT_APP_LOGO_URL3}`],
	[parseFloat(`${process.env.REACT_APP_LOGO_URL2_SIZE}`), `${process.env.REACT_APP_LOGO_URL2}`],
	[parseFloat(`${process.env.REACT_APP_LOGO_URL_SIZE}`), `${process.env.REACT_APP_LOGO_URL}`],
	['src', `${process.env.REACT_APP_LOGO_URL}`]
]);

export const LogoBlackSrcMap = SrcMap.createSrcMap([
	[parseFloat(`${process.env.REACT_APP_LOGO_BLACK_URL3_SIZE}`), `${process.env.REACT_APP_LOGO_BLACK_URL3}`],
	[parseFloat(`${process.env.REACT_APP_LOGO_BLACK_URL2_SIZE}`), `${process.env.REACT_APP_LOGO_BLACK_URL2}`],
	[parseFloat(`${process.env.REACT_APP_LOGO_BLACK_URL_SIZE}`), `${process.env.REACT_APP_LOGO_BLACK_URL}`],
	['src', `${process.env.REACT_APP_LOGO_BLACK_URL}`]
]);

export const ClientLogoSrcMap = undefined;

export const ClientLoginSrcMap = SrcMap.createSrcMap([
	[1956, loginImage3],
	[1304, loginImage2],
	[652, loginImage1],
	['src', loginImage1]
]);

export const SiteName = `${process.env.REACT_APP_SITE_NAME}`;

export const ClientName = '';

export const ClientLoginQuote = `Gold medals aren't really made of gold. They're made of sweat, determination, and a hard-to-find alloy called guts.`;
export const ClientLoginQuoteAuthor = `Dan Gable`;