import React, {Fragment, useState, useEffect, useRef} from 'react';
import clsx from "clsx";
import {connect, ConnectedProps} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import NavDrawer from '../nav-drawer/nav-drawer.component';
import Avatar from '../avatar/avatar.component';
import {selectTeamInfo} from '../../redux/team/team.selectors';
import {selectCurrentUser} from '../../redux/user/user.selectors';
import {signOutStart} from '../../redux/user/user.actions'
import {ITeam} from '../../redux/team/team.types';
import {IUser} from '../../redux/user/user.types';
import {IRootState} from '../../redux/root.types';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import {SrcMapType} from '../../utils/src-map';
import { Sizes, LogoSrcMap, ComponentColors, ClientLogoSrcMap, ClientName } from '../../utils/style';
import ResponsiveImage from '../responsive-image/responsonsive-image.component';


const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.typography.pxToRem(10),
        //marginLeft: theme.typography.pxToRem(-12),
        paddingLeft: theme.typography.pxToRem(15),
        paddingRight: theme.typography.pxToRem(4),

        '& .MuiSvgIcon-root': {
            fontSize: theme.typography.pxToRem(40)
        },
    },
    grow: {
        flexGrow: 1
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    offset: {
        ...theme.mixins.toolbar
    },
    toolbar: {
        width: "90%",
        maxWidth: Sizes.MaxWidth,
        marginLeft: "auto",
        marginRight: "auto"
    },
    toolbarHeight: {
        minHeight: '60px',
        maxHeight: '60px',
        paddingLeft: 0,
        paddingRight: theme.typography.pxToRem(13),
    },
    splitLeft: {
        flex: '1 0 auto',
        alignItems: 'center',
        
        [theme.breakpoints.down("xs")]: {
            marginLeft: -6
        },
    },
    splitRight: {
        flex: '0 0 auto',
        alignItems: 'center',
        justifyContent: 'flex-end',

        [theme.breakpoints.down("xs")]: {
            marginRight: -10
        },
    },
    avatar: {
        width: theme.typography.pxToRem(40),
        height: theme.typography.pxToRem(40),

        '&.fallback': {
            width: theme.typography.pxToRem(30),
            height: theme.typography.pxToRem(30),
            color: `${ComponentColors.NavBar}`,
            backgroundColor: '#717171'
        }
    },
    teamLogo: {
        height: theme.typography.pxToRem(40),
        marginLeft: theme.typography.pxToRem(5),
        marginRight: theme.typography.pxToRem(19),
        overflow: 'visible',
    },
    teamInfo: {
        display: "flex",
        alignItems: 'center',
    },
    teamHeader: {
        color: 'inherit',
    },
    teamTitle: {
        lineHeight: theme.typography.pxToRem(22)
    },
    teamOccupation: {
        lineHeight: theme.typography.pxToRem(18)
    },
    flexStack: {
        display: 'flex',
        flexDirection: 'column'
    },
    userMenuTrigger: {
        padding: 0,
        margin: 0
    },
    logo: {
        flexGrow: 0,
        flexShrink: 1,
        paddingLeft: theme.typography.pxToRem(18),

        '& img': {
            height: theme.typography.pxToRem(28)
        }
    }
}));

interface mapStateToPropsInterface  {
    team?:ITeam;
    user:IUser | undefined | null;
}
const mapStateToProps = createStructuredSelector<IRootState, mapStateToPropsInterface>({
    team: selectTeamInfo,
    user: selectCurrentUser
});

const mapDispatchToProps = {
    signOutStart
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;

type HeaderProps = PropsFromRedux & {
    userMenuOpen?:boolean;
    siteName?:string;
    logoSrcMap?:SrcMapType;
};

export const Header:React.FC<HeaderProps> = ({team, user, signOutStart, siteName, logoSrcMap, userMenuOpen=false}) => {
    const classes = useStyles();

    siteName = siteName || `${process.env.REACT_APP_SITE_NAME}`;
    logoSrcMap = logoSrcMap || LogoSrcMap;

    // Nav Drawer
    const [drawerOpen, setDrawerOpen] = useState(false);
    
    // Account Menu
    const [menuOpen, setMenuOpen] = useState(userMenuOpen);
    const menuTriggerRef = useRef(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(menuOpen);
    useEffect(() => {
        if (prevOpen.current === true && menuOpen === false && menuTriggerRef && menuTriggerRef.current) {
            (menuTriggerRef.current! as HTMLButtonElement).focus();
        }
        prevOpen.current = menuOpen;
    }, [menuOpen]);

    const handleMenuToggle = () => {
        setMenuOpen(prevOpen => !prevOpen);
    };

    const handleMenuClose = (event:React.MouseEvent<Document>) => {
        if (menuTriggerRef.current && (menuTriggerRef.current! as HTMLButtonElement).contains(event.target as Node)) {
            return;
        }

        setMenuOpen(false);
    };

    const handleMenuListKeyDown = (event:React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setMenuOpen(false);
        }
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleSignOutClick = (event:React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        signOutStart();
        setMenuOpen(false);
    };


    return (
        <Fragment>
            <AppBar position="fixed">
                <Toolbar className={clsx(classes.toolbar, classes.toolbarHeight)}>
                    <Box display="flex" className={classes.splitLeft}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Open Nav"
                            onClick={handleDrawerOpen}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className={classes.grow}>
                            {team && (
                                <div className={classes.teamInfo}>
                                    {team.image && (
                                        <Hidden xsDown>
                                            {/*<Avatar variant="rounded" alt={team.displayName} src={team.image} className={`${classes.avatar} ${classes.teamLogo}`} />*/}
                                            {ClientLogoSrcMap && (
                                                <ResponsiveImage srcMapping={ClientLogoSrcMap} alt={ClientName} className={classes.teamLogo} sizeToHeight />
                                            )}
                                        </Hidden>
                                    )}
                                    <div className={classes.flexStack}>
                                        <Typography component="span" variant="h2" className={`${classes.teamHeader} ${classes.teamTitle}`}>
                                            {team.displayName}
                                        </Typography>
                                        <Typography component="span" className={`${classes.teamHeader} ${classes.teamOccupation}`}>
                                            {team.occupation}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box>
                    <Box display="flex" className={classes.splitRight}>
                        {user && (
                            <Fragment>
                                <IconButton
                                    className={classes.userMenuTrigger}
                                    ref={menuTriggerRef} 
                                    onClick={handleMenuToggle} 
                                    edge="end"
                                    aria-label="Account Menu"
                                    //aria-controls={menuId}
                                    aria-haspopup="true"
                                    //onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <Avatar variant="rounded" alt={user.displayName} src={user.image} className={classes.avatar} />
                                </IconButton>
                                <Popper open={menuOpen} anchorEl={menuTriggerRef.current || document?.body} role={undefined} transition disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleMenuClose}>
                                                <MenuList autoFocusItem={menuOpen} onKeyDown={handleMenuListKeyDown}>
                                                    <MenuItem>Account Info</MenuItem>
                                                    <MenuItem className="sign-out" onClick={handleSignOutClick}>Sign Out</MenuItem>
                                                </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Fragment>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            <div className={clsx(classes.offset, classes.toolbarHeight)} />
            <NavDrawer isOpen={drawerOpen} onDrawerClose={handleDrawerClose} />
        </Fragment>
    )
};

export default connector(Header);