import {
    UPDATE_CORRECTIVE,
    GET_ALL_CORRECTIVES,
    SHOW_ALL_CORRECTIVES_ERROR,
    GET_CORRECTIVE,
    SHOW_CORRECTIVE_ERROR,
    SET_CORRECTIVES_LOADING,
    SET_ALL_CORRECTIVES,
    IGetAllCorrectives,
    IShowAllCorrectivesError,
    IGetCorrective,
    IGetCorrectivePayload,
    IShowGetCorrectiveError,
    IShowGetCorrectiveErrorPayload,
    IUpdateCorrective,
    ISetCorrectivesLoading,
    IUpdateCorrectivePayload,
    ISetAllCorrectives,
    ISetAllCorrectivesPayload
} from './correctives.types';

export const setLoading = (val:boolean):ISetCorrectivesLoading => ({
    type: SET_CORRECTIVES_LOADING,
    payload: val
});

export const getAllCorrectives = (playerId:string):IGetAllCorrectives => ({
    type: GET_ALL_CORRECTIVES,
    payload: playerId
});

export const showAllCorrectivesError = (error:string):IShowAllCorrectivesError => ({
    type: SHOW_ALL_CORRECTIVES_ERROR,
    payload: error
});

export const getCorrective = (config:IGetCorrectivePayload):IGetCorrective => ({
    type: GET_CORRECTIVE,
    payload: config
});

export const showGetCorrectiveError = (config:IShowGetCorrectiveErrorPayload):IShowGetCorrectiveError => ({
    type: SHOW_CORRECTIVE_ERROR,
    payload: config
});

export const updateCorrective = (config:IUpdateCorrectivePayload):IUpdateCorrective => ({
    type: UPDATE_CORRECTIVE,
    payload: config
});

export const setCorrectives = (config:ISetAllCorrectivesPayload):ISetAllCorrectives => ({
    type: SET_ALL_CORRECTIVES,
    payload: config
});