export const SET_PLAYER_LOADING = 'SET_PLAYER_LOADING';
export const GET_PLAYER = 'GET_PLAYER';
export const SET_PLAYER = 'SET_PLAYER';
export const SHOW_PLAYER_ERROR = 'SHOW_PLAYER_ERROR';

// Data types
export interface IData {
	id:string;
}

export enum Status {
	MissedSevere = 1,
	Missed = 2,
	Skipped = 3,
	NeedAssessment = 4,
	OnTrack = 5
}

export interface IPlayer extends IData {
	name: string;
	title:string | null | undefined;
	lastLogin:string;
	lastWorkout:string;
	lastAssessment:string;
	status:Status | number;
	overallStatus:Status | number;
	completedDays:number;
	totalDays:number;
	skippedDays:number;
	missedDays:number;
	image:string | null | undefined;
	teamId:string;
}


// State type
export interface IPlayerState {
	isLoading:boolean;
	player?:IPlayer;
	error?:(string | null);
}


// Action types
export interface IGetPlayerPayload {
	id:string;
	team:boolean; // whether to include team data as well
	correctives:boolean; // whether to include shallow correctives info
	assessments:boolean; // whether to include shallow assessments info
}
export interface IGetPlayer {
	type: typeof GET_PLAYER;
	payload: IGetPlayerPayload;
};

export interface ISetPlayerLoading {
    type: typeof SET_PLAYER_LOADING;
    payload: boolean;
};

export interface ISetPlayer {
    type: typeof SET_PLAYER;
    payload?:IPlayer;
}

export interface IShowPlayerError {
    type: typeof SHOW_PLAYER_ERROR;
    payload:string;
}


// Classes
export class Player implements IPlayer {
	
	constructor(
		public readonly id:string,
		public readonly name: string,
		public readonly teamId: string,
		public readonly lastLogin:string,
		public readonly lastWorkout:string,
		public readonly lastAssessment:string,
		public readonly status:Status | number,
		public readonly overallStatus:Status | number,
		public readonly completedDays:number,
		public readonly totalDays:number,
		public readonly skippedDays:number,
		public readonly missedDays:number,
		public readonly title:string | null | undefined,
		public readonly image:string | null | undefined
	){}

	
	static create({id, name, teamId, lastLogin, lastWorkout, lastAssessment, status, overallStatus, completedDays, totalDays, skippedDays, missedDays, title, image}:IPlayer):Player {
		return new Player(id, name, teamId, lastLogin, lastWorkout, lastAssessment, status, overallStatus, completedDays, totalDays, skippedDays, missedDays, title, image);
	}

	/*static clone({correctives, updateCorrective, ...otherProps}:IPlayer):Player {
		const clone = Player.create(otherProps);
		if( correctives ){
			clone.correctives = [
				...correctives
			];
		}
		return clone;
	}*/
}

export type TypePlayerAction = IGetPlayer | ISetPlayerLoading | ISetPlayer | IShowPlayerError;