import {SHOW_ERROR, SET_TEAM_LOADING, IShowError, ISetTeamLoading, ISetTeam, SET_TEAM, ITeam, GET_TEAM, IGetTeam} from './team.types';

export const showError = (error:string):IShowError => ({
    type: SHOW_ERROR,
    payload: error
});

export const setLoading = (val:boolean):ISetTeamLoading => ({
    type: SET_TEAM_LOADING,
    payload: val
});

export const setTeam = (teamData:ITeam):ISetTeam => ({
    type: SET_TEAM,
    payload: teamData
});

export const getTeam = ():IGetTeam => ({
    type: GET_TEAM
});