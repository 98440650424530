import {takeLatest, takeEvery, put, all, call} from 'redux-saga/effects';
import axios from 'axios';
import {
	GET_PLAYER_ASSESSMENT, 
	GET_ALL_PLAYER_ASSESSMENTS,
	IGetPlayerAssessment, 
	IGetAllPlayerAssessments, 
	IAssessmentsProgram, 
	IAssessmentCompleted, 
	IAssessmentsProgramLimited,
	LoadableProgramType
} from './assessments.types';
import {showAllAssessmentsError, setLoading, updateAssessmentProgram, showGetAssessmentProgramError, setAllAssessmentPrograms} from './assessments.actions';

export interface IAssessmentProgramDayRemote extends IAssessmentCompleted {}
export interface IAssessmentProgramRemote extends IAssessmentsProgram {}
export type LoadableAssessmentProgramRemoteType = IAssessmentsProgramLimited | IAssessmentProgramRemote;
interface IAllAssessmentProgramsRemote {
	assessments:LoadableAssessmentProgramRemoteType[]
}

export function* getAssessmentProgram({payload: {id, playerId} }:IGetPlayerAssessment) {
	// get info
	try {
		const api = `${process.env.PUBLIC_URL}/data/assessment-program1.json`;
		const correctiveInfoData = yield axios(api);
		const assessmentProgramInfo:IAssessmentProgramRemote = correctiveInfoData?.data as IAssessmentProgramRemote;
		if( assessmentProgramInfo ){
			
			const program = convertAssessmentProgramRemoteData(assessmentProgramInfo);
			
			yield put(
				updateAssessmentProgram({
					program,
					playerId
				})
			);
			
		} else {
			throw new Error('Assessment Program API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showGetAssessmentProgramError({
				programId: id,
				error: 'Get assessments program data failed.'
			})
		);
	}
    
}

export function* getAllAssessmentPrograms({payload: playerId}:IGetAllPlayerAssessments) {
	yield put(
		setLoading(true)
	);
	
	// get info
	try {
		const api = `${process.env.PUBLIC_URL}/data/player-assessments.json?id=${playerId}`;
		const correctivesInfoData = yield axios(api);
		const assessmentProgramsInfo:IAllAssessmentProgramsRemote = correctivesInfoData?.data as IAllAssessmentProgramsRemote;
		if( assessmentProgramsInfo && assessmentProgramsInfo.assessments ){
			
			const programs = convertAllAssessmentProgramsRemoteData(assessmentProgramsInfo.assessments);
			
			yield put(
				setAllAssessmentPrograms({
					programs,
					playerId
				})
			);
		} else {
			throw new Error('All assessment programs API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showAllAssessmentsError('Get all assessment programs data failed.')
		);
	}

	yield put(
		setLoading(false)
	);
    
}

export const convertAssessmentProgramRemoteData = ({days, ...correctivedata}:IAssessmentProgramRemote):IAssessmentsProgram => {
	const convertedDays:IAssessmentCompleted[] = days;
	return {
		...correctivedata,
		days: convertedDays
	} as IAssessmentsProgram;
}

export const convertAllAssessmentProgramsRemoteData = (correctives:LoadableAssessmentProgramRemoteType[]):LoadableProgramType[] => {
	const convertedAssessmentPrograms:LoadableProgramType[] = correctives.map(corrective => {
		return !!(corrective as IAssessmentProgramRemote).days ? convertAssessmentProgramRemoteData((corrective as IAssessmentProgramRemote)) : corrective;
	});
	return convertedAssessmentPrograms;
}

export function* onGetAssessmentProgram() {
    yield takeEvery(GET_PLAYER_ASSESSMENT, getAssessmentProgram);
}

export function* onGetAllAssessmentPrograms() {
    yield takeLatest(GET_ALL_PLAYER_ASSESSMENTS, getAllAssessmentPrograms);
}

export function* assessmentsSagas() {
    yield all([
		call( onGetAssessmentProgram ),
		call( onGetAllAssessmentPrograms )
    ]);
}