import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
	makeStyles,
	Theme,
	createStyles,
	withStyles,
	ThemeProvider
} from "@material-ui/core/styles";

import Header from "./components/header/header.component";
import ErrorBoundary from "./components/error-boundary/error-boundary.component";
import AppTheme from "./App.theme";
import { IRootState } from "./redux/root.types";

import { IUser } from "./redux/user/user.types";
import { selectCurrentUser } from "./redux/user/user.selectors";
import { checkUserSession } from "./redux/user/user.actions";
import Routes, {PrivateRoute} from './utils/routes';
import { Sizes } from './utils/style';
import Footer from './components/footer/footer.component';
import SpinnerBackdrop from './components/spinner-backdrop/spinner-backdrop.component';
import { globalStyles } from './App.theme';

const HomePage = lazy(() => import("./pages/homepage/homepage.component"));
const LoginPage = lazy(() => import("./pages/login/loginpage.component"));
const AssessmentPage = lazy(() => import("./pages/assessment/assessment.component"));
const PlayerPage = lazy(() => import("./pages/player/player.component"));
const ExercisePage = lazy(() => import("./pages/exercise/exercise.component"));

/*if (process.env.NODE_ENV !== 'production') {
	const whyDidYouRender = require('@welldone-software/why-did-you-render');
	whyDidYouRender(React, { include: [/^ConnectFunction$/] });
}*/

const headerHeight = 60;
const footerHeight = 84;
const desktopDiff = headerHeight + footerHeight + 2;
const footerMobileHeight = 76;
const mobileDiff = headerHeight + footerMobileHeight + 2;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "90%",
			maxWidth: Sizes.MaxWidth,
			marginLeft: "auto",
			marginRight: "auto",

			'& .positionRelative': {
				position: 'relative',
			},

			[theme.breakpoints.down("sm")]: {

				'& .fullWidthMobile': {
					width: '100vw',
					maxWidth: '100vw',
					marginLeft: 'calc((100vw - (100vw * .9)) / -4)',
					marginRight: 'calc((100vw - (100vw * .9)) / -4)',
				}
			},
		},
		main: {
			minHeight: `calc(100vh - ${desktopDiff}px)`,

			[theme.breakpoints.down("sm")]: {
				minHeight: `calc(100vh - ${mobileDiff}px)`,
			},
		}
	})
);

interface mapStateToPropsInterface {
	currentUser?: IUser | null;
}
const mapStateToProps = createStructuredSelector<
	IRootState,
	mapStateToPropsInterface
>({
	currentUser: selectCurrentUser
});

const mapDispatchToProps = {
	checkUserSession
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type AppProps = PropsFromRedux & {};

export const App: React.FC<AppProps> = ({ checkUserSession, currentUser }) => {
	const classes = useStyles();

	const location = useLocation();
	type hasFrom = {
		from?:string;
	}
	const originalFrom = (location.state as hasFrom)?.from;
	const from = originalFrom ? originalFrom : Routes.HOME;

	useEffect(() => {
		checkUserSession();
	}, [checkUserSession]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<ThemeProvider theme={AppTheme}>
			<div className={classes.root}>
				<CssBaseline />
				{currentUser && (
					<Header />
				)}
				<div className={classes.main}>
					<Switch>
						<ErrorBoundary>
							<Suspense fallback={<SpinnerBackdrop isLoading={true} />}>
								<PrivateRoute
									exact
									path={Routes.HOME}
									component={HomePage}
									isAuthenticated={!!currentUser}
								/>
								<Route
									exact
									path={Routes.LOGIN}
									render={() =>
										currentUser ? (
											<Redirect to={from} />
										) : (
											<LoginPage />
										)
									}
								/>
								<PrivateRoute
									exact
									path={Routes.ASSESSMENT}
									component={AssessmentPage}
									isAuthenticated={!!currentUser}
								/>
								<PrivateRoute
									path={Routes.PLAYER}
									component={PlayerPage}
									isAuthenticated={!!currentUser}
								/>
								<PrivateRoute
									path={Routes.EXERCISE}
									component={ExercisePage}
									isAuthenticated={!!currentUser}
								/>
							</Suspense>
						</ErrorBoundary>
					</Switch>
				</div>	
				{currentUser && (
					<Footer />
				)}
			</div>
		</ThemeProvider>
	);
};

export default connector(withStyles(globalStyles)(App));
