import {takeLatest, takeEvery, put, all, call} from 'redux-saga/effects';
import axios from 'axios';
import {
	GET_CORRECTIVE, 
	GET_ALL_CORRECTIVES,
	IGetCorrective, 
	IGetAllCorrectives, 
	ICorrective, 
	ICorrectiveDay, 
	ICorrectiveLimited,
	LoadableCorrectiveType
} from './correctives.types';
import {showAllCorrectivesError, setLoading, updateCorrective, showGetCorrectiveError, setCorrectives} from './correctives.actions';

export interface ICorrectiveDayRemote extends ICorrectiveDay {}
export interface ICorrectiveRemote extends ICorrective {}
export type LoadableCorrectiveRemoteType = ICorrectiveLimited | ICorrectiveRemote;
interface IAllCorrectivesRemote {
	correctives:LoadableCorrectiveRemoteType[]
}

export function* getCorrective({payload: {id, playerId} }:IGetCorrective) {
	// get info
	try {
		const api = `${process.env.PUBLIC_URL}/data/corrective${id}.json`;
		const correctiveInfoData = yield axios(api);
		const correctiveInfo:ICorrectiveRemote = correctiveInfoData?.data as ICorrectiveRemote;
		if( correctiveInfo ){
			
			const corrective = convertCorrectiveRemoteData(correctiveInfo);
			
			yield put(
				updateCorrective({
					corrective,
					playerId
				})
			);
			
		} else {
			throw new Error('Corrective API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showGetCorrectiveError({
				correctiveId: id,
				error: 'Get corrective data failed.'
			})
		);
	}
    
}

export function* getAllCorrectives({payload: playerId}:IGetAllCorrectives) {
	yield put(
		setLoading(true)
	);
	
	// get info
	try {
		const api = `${process.env.PUBLIC_URL}/data/player-correctives.json?id=${playerId}`;
		const correctivesInfoData = yield axios(api);
		const correctivesInfo:IAllCorrectivesRemote = correctivesInfoData?.data as IAllCorrectivesRemote;
		if( correctivesInfo && correctivesInfo.correctives ){
			
			const correctives = convertAllCorrectivesRemoteData(correctivesInfo.correctives);
			
			yield put(
				setCorrectives({
					correctives,
					playerId
				})
			);
		} else {
			throw new Error('All Correctives API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showAllCorrectivesError('Get all correctives data failed.')
		);
	}

	yield put(
		setLoading(false)
	);
    
}

export const convertCorrectiveRemoteData = ({days, ...correctivedata}:ICorrectiveRemote):ICorrective => {
	const convertedDays:ICorrectiveDay[] = days;
	return {
		...correctivedata,
		days: convertedDays
	} as ICorrective;
}

export const convertAllCorrectivesRemoteData = (correctives:LoadableCorrectiveRemoteType[]):LoadableCorrectiveType[] => {
	const convertedCorrectives:LoadableCorrectiveType[] = correctives.map(corrective => {
		return !!(corrective as ICorrectiveRemote).days ? convertCorrectiveRemoteData((corrective as ICorrectiveRemote)) : corrective;
	});
	return convertedCorrectives;
}

export function* onGetCorrective() {
    yield takeEvery(GET_CORRECTIVE, getCorrective);
}

export function* onGetAllCorrectives() {
    yield takeLatest(GET_ALL_CORRECTIVES, getAllCorrectives);
}

export function* correctivesSagas() {
    yield all([
		call( onGetCorrective ),
		call( onGetAllCorrectives )
    ]);
}