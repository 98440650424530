import {IPlayer} from '../player/player.types';

export const SET_TEAM_LOADING = 'SET_TEAM_LOADING';
export const GET_TEAM = 'GET_TEAM';
export const SET_TEAM = 'SET_TEAM';
export const SHOW_ERROR = 'SHOW_ERROR';

// Data types
export interface ITeam {
    id:string;
	displayName:string;
	occupation:string;
	image:string | null | undefined;
	members?:IPlayer[];
}


// State type
export interface ITeamState {
	isLoading:boolean;
	team?:ITeam;
    error?:(string | null);
}


// Action types
export interface IGetTeam {
    type: typeof GET_TEAM;
};

export interface ISetTeamLoading {
    type: typeof SET_TEAM_LOADING;
    payload: boolean;
};

export interface ISetTeam {
    type: typeof SET_TEAM;
    payload:ITeam;
}

export interface IShowError {
    type: typeof SHOW_ERROR;
    payload:string;
}

// Classes
export class Team implements ITeam {
	constructor(
		public readonly id:string,
		public readonly displayName:string,
		public readonly occupation:string,
		public readonly image:string | null | undefined,
		public members?:IPlayer[]
	){}

	static create({id, displayName, occupation, image, members}:ITeam):Team {
		return new Team(id, displayName, occupation, image, members);
	}
}

export type TypeTeamAction = IGetTeam  | ISetTeamLoading | ISetTeam | IShowError;