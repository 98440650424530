import {takeLatest, put, all, call} from 'redux-saga/effects';
import axios from 'axios';

import {
    IUser,
    TypeEmailSignInStartAction,
    EMAIL_SIGN_IN_START,
    CHECK_USER_SESSION,
    SIGN_OUT_START
} from './user.types';
import {signInSuccess, signInFailure, signOutSuccess, signOutFailure} from './user.actions';
import {StorageKey, getSession, setSession, deleteSession} from '../../utils/storage';

interface IRemoteUserInfo extends IUser {
	password:string;
}
export function* signInWithEmail({ payload: {email, password} }:TypeEmailSignInStartAction) {
    try {
		const userInfoData = yield axios(`${process.env.PUBLIC_URL}/data/user.json`);
		const user:IRemoteUserInfo = userInfoData?.data as IRemoteUserInfo;
		if( user && user.email===email && user.password===password ){ //this validation will be done server-side
            delete user.password;
            setSession(StorageKey.User, user);
			yield put(
				signInSuccess( user as IUser )
			);
		} else {
            yield put(signInFailure('Incorrect username or password'));
		}

    } catch(error) {
        yield put(signInFailure(error));
    }
}

export function* isUserAuthenticated() {
    try {
        // can use api instead
		const user:IUser | null = getSession(StorageKey.User);
		if( user ){
			yield put(
				signInSuccess(user)
			);
		} else {
			return;
		}
    } catch(error){
        yield put(signInFailure(error));
    }
}

export function* signOut() {
    try {
		deleteSession(StorageKey.User);
        yield put(signOutSuccess());
    } catch(error){
        yield put(signOutFailure(error));
    }
}

export function* onEmailSignInStart() {
    yield takeLatest(EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
    yield takeLatest(CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
    yield takeLatest(SIGN_OUT_START, signOut);
}

export function* userSagas() {
    yield all([
        call( onEmailSignInStart ),
        call( isUserAuthenticated ),
        call( onSignOutStart )
    ]);
}